<template>
  <div>
    <Header></Header>
    <div class="cpal1">
      <img class="cpalA1" src="@/assets/cpal.png" alt="" />
      <div class="cpalB1">产品案例 - 工业制冷云平台</div>
      <div class="cpalC1">INDUSTRIAL COLD CLOUD PLATFORM</div>
    </div>
    <div class="body">
      <div>
        <div class="cpszh">工业制冷云平台</div>
        <div class="bodyTitle">
          <div class="product">INDUSTRIAL COLD CLOUD PLATFORM</div>
          <!-- <div class="more">
            <div class="datetime">2022-10-23</div>
          </div> -->
        </div>
      </div>
      <div class="bodyContent">
        <div>
          <img class="cpalb1" src="@/assets/工业制冷云平台.png" alt="" />
          <img class="cpalb1" src="@/assets/工业制冷云平台1.png" alt="" />
        </div>
        <div class="fontA11">
          &emsp;&emsp;工业物联网制冷云平台是一套针对制冷行业生产经营过程中各种系统设备进行综合管理，集项目展示、实时操控、冷间详情、监控、能耗展示、报警统计、售后为一体，提高生产经营者的管理效率，实现数据的即时、准确、形象展示。根据项目、系统、冷间、设备进行分级管理。平台包括管理系统和手机APP两部分。
        </div>
      </div>
      <div class="LastNext">
        <div class="last" @click="sevenL">上一案例：{{ last }}</div>
        <div class="next" @click="sevenN">下一案例：{{ next }}</div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/views/header.vue";
import Footer from "@/views/footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      last: "智慧农业",
      next: "执法办案云平台",
    };
  },
  methods: {
    sevenL() {
        this.$router.push({ name: "productCase6" });
    },
    sevenN() {
        this.$router.push({ name: "productCase8" });
    }
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.lbt {
  width: 100%;
}
.body {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 80px;
  background: #fff !important;
}
.cpszh {
  font-size: 24px;
  padding: 40px 0 4px 32px;
  color: #183f85;
  letter-spacing: 1px;
}
.bodyTitle {
  display: flex;
  justify-content: space-between;
  margin-right: 32px;
}
.product {
  font-size: 24px;
  color: #bfbfbf;
  margin-left: 32px;
  letter-spacing: 1px;
}
.more {
  display: flex;
}
.datetime {
  font-size: 20px;
  color: #7d7d7d;
  letter-spacing: 1px;
}
.bodyContent {
  display: block;
  border-bottom: 1px solid #d8d8d8;
}
.cpalb1 {
    width: 100%;
  margin: 24px 32px;
}
.fontA1 {
  font-size: 20px;
  letter-spacing: 1px;
  margin: 0 24px 0 32px;
  line-height: 40px;
}
.fontA11 {
  font-size: 20px;
  letter-spacing: 1px;
  margin: 0 24px 46px 32px;
  line-height: 40px;
}
.LastNext {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  font-size: 20px;
  color: #7d7d7d;
  letter-spacing: 1px;
}
.last {
    cursor: pointer;
}
.next {
    cursor: pointer;
}
.cpal1 {
  position: relative;
}
.cpalA1 {
  width: 100%;
}
.cpalB1 {
  position: absolute;
  top: 200px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 60px;
  color: #fff;
  line-height: 33px;
  letter-spacing: 1px;
}
.cpalC1 {
  position: absolute;
  top: 280px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 24px;
  color: #bfbfbf;
  line-height: 33px;
  letter-spacing: 1px;
}
</style>
